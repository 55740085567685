import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/InteractiveIllustration.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/mobilise/CreateGroupLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/mobilise/CreateOrganisationLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/twoFootprints/LearnMoreCarbonLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/twoFootprints/LearnMoreWaterLink.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/twoFootprints/LocalizedCarbonGraphIllustration.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/twoFootprints/LocalizedTwoGraphIllustration.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/twoFootprints/LocalizedWaterGraphIllustration.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/cta/DynamicCTAButtons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/landing-pages/DidYouKnowSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/landing-pages/titleDescLinkBlock/TitleDescLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/translation/trans/TransClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/design-system/cms/PostThumbnail.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/design-system/layout/Main.tsx");
