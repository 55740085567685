import { twMerge } from 'tailwind-merge'

export default function RestartIcon({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge('inline-block fill-default stroke-[1.5]', className)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4C5.55228 4 6 4.44772 6 5V19C6 19.5523 5.55228 20 5 20C4.44772 20 4 19.5523 4 19V5C4 4.44772 4.44772 4 5 4ZM17.9978 4.08063C18.6069 4.07997 19.1831 4.3569 19.5631 4.83292C19.8654 5.2116 19.9379 5.66715 19.9686 6.00542C20.0001 6.35179 20 6.78985 20 7.28453V16.7155C20 17.2102 20.0001 17.6482 19.9686 17.9946C19.9379 18.3329 19.8654 18.7884 19.5631 19.1671C19.1831 19.6431 18.6069 19.92 17.9978 19.9194C17.5133 19.9189 17.1123 19.6909 16.829 19.5036C16.5388 19.3118 16.1968 19.0381 15.8105 18.729L9.90858 14.0075C9.66627 13.8137 9.43621 13.6297 9.25837 13.4598C9.06773 13.2777 8.85308 13.0358 8.72788 12.7016C8.55842 12.2492 8.55842 11.7508 8.72788 11.2984C8.85308 10.9642 9.06773 10.7223 9.25837 10.5402C9.4362 10.3703 9.66624 10.1863 9.90853 9.99254C9.91802 9.98496 9.92752 9.97736 9.93704 9.96974L15.8105 5.27095C16.1968 4.96191 16.5388 4.68824 16.829 4.49643C17.1123 4.30911 17.5133 4.08115 17.9978 4.08063ZM17.9319 6.16478C17.7271 6.30021 17.4568 6.51515 17.0252 6.86048L11.1864 11.5315C10.9053 11.7564 10.7461 11.8849 10.6398 11.9864C10.6348 11.9912 10.6301 11.9957 10.6258 12C10.6301 12.0043 10.6348 12.0088 10.6398 12.0136C10.7461 12.1151 10.9053 12.2436 11.1864 12.4685L17.0252 17.1395C17.4568 17.4848 17.7271 17.6998 17.9319 17.8352C17.9463 17.8447 17.9596 17.8533 17.972 17.8611C17.9736 17.8466 17.9752 17.8308 17.9768 17.8137C17.999 17.5691 18 17.2238 18 16.671V7.329C18 6.77621 17.999 6.43089 17.9768 6.18634C17.9752 6.16921 17.9736 6.15341 17.972 6.13889C17.9596 6.14669 17.9463 6.1553 17.9319 6.16478Z"
      />
    </svg>
  )
}
